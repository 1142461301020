import { observer } from "mobx-react";
import { Box, Flex, Grid, VStack } from "@chakra-ui/react";
import React, { useCallback } from "react";
import { RadioInputV2 } from "components/radio-input/RadioInputV2";

interface IProps {
  onChange: (value: boolean) => void;
  value: boolean;
}

export const AccountEmailNotificationRecipientPreferences: React.FC<IProps> = observer(
  ({ onChange, value }) => {
    const handleChange = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        const isEnabled = event.target.value === "true";
        onChange(isEnabled);
      },
      [onChange]
    );

    return (
      <VStack align={"left"} spacing={8}>
        <Box fontSize={"xl"} fontWeight={"bold"} color={"leadpro.700"}>
          Account Email Notification Preferences
        </Box>
        <Grid templateColumns="1fr auto" gap={6} alignItems="center">
          <VStack alignItems={"flex-start"} justify={"center"}>
            <Box fontWeight={"bold"} fontSize={"sm"} color={"gray.500"}>
              Always send notification emails to the office
            </Box>
            <Box fontSize={"sm"} color={"gray.500"}>
              Select this option if you’d prefer to have all notification emails
              sent to the office associated with the lead.
            </Box>
          </VStack>
          <Flex alignItems={"center"} justifyContent={"center"} pr={5}>
            <RadioInputV2
              name="office-notification-preference"
              value="false"
              isChecked={!value}
              onChange={handleChange}
            />
          </Flex>

          <VStack alignItems={"flex-start"} justify={"center"}>
            <Box fontWeight={"bold"} fontSize={"sm"} color={"gray.500"}>
              Prefer to send notification emails to the lead assignee
            </Box>
            <Box fontSize={"sm"} color={"gray.500"}>
              Select this option if you’d prefer to have notification emails
              sent to the assigned agent, where new leads have agents assigned
              prior to sending the notification email. In cases where leads have
              no assigned user, the notification will instead be sent to the
              associated office.
            </Box>
          </VStack>
          <Flex alignItems={"center"} justifyContent={"center"} pr={5}>
            <RadioInputV2
              name={"notification-preference"}
              value="true"
              isChecked={value}
              onChange={handleChange}
            />
          </Flex>
        </Grid>
      </VStack>
    );
  }
);
