import { TAvailableLeadsFilters, TLeadsFilter } from "types/leads-filter.type";
import { keysToCamel } from "utils/keys-to-camel.utils";
import { BaseApi } from "./base.api";
import { SortingRule } from "react-table";
import {
  TContactNote,
  TGetLeadsResponse,
  TLead,
  TLeadWithDetails,
} from "types/lead.type";
import { TAssignableToLeadUserData } from "types/user-data.type";
import { LeadSimpleFilterTypes } from "enums/leads-simple-filter-type.enum";
import {
  transformLeadDateRangeQueryParam,
  transformLeadSourcesQueryParam,
  transformNextActionDateQueryParam,
  transformQuestionnaireQueryParam,
  transformSortByQueryParam,
} from "utils/api.utils";
import { TLeadReapitData, TLeadReapitSyncData } from "types/reapit.type";
import { TLeadMRIRadarData } from "types/mri-radar.type";
import { TManualAddLeadData } from "types/manual-add-lead.type";
import {
  questionnaireAnsweredBoolToEnum,
  questionnaireAnsweredEnumToBool,
} from "utils/questionnaire-answered-filter.utils";
import { TLeadOpenViewData, TLeadOpenViewSyncData } from "types/openview.type";
import { TLeadUrlSyncData } from "../types/url-sync.type";
import { TLeadAltoAddress, TLeadAltoSyncData } from "../types/alto.type";

export class LeadsApi extends BaseApi {
  async getLeads(
    accountId: number,
    filter: TLeadsFilter,
    sortBy: SortingRule<string>[],
    searchQuery: string,
    includeSpeedScores: boolean,
    pageSize: number,
    page: number
  ) {
    const sortByTransformed = transformSortByQueryParam(sortBy);
    const nextActionDateTransformed = transformNextActionDateQueryParam(
      filter.nextActionDate
    );

    const dateRangeTransformed = transformLeadDateRangeQueryParam(
      filter.dateRange
    );
    const sourcesTransformed = transformLeadSourcesQueryParam(filter.sources);
    const questionnaireTransformed = transformQuestionnaireQueryParam(
      filter.questionnaire
    );
    const questionnaireAnsweredTransformed = questionnaireAnsweredEnumToBool(
      filter.questionnaireAnswered
    );

    const adaptedFilter = {
      ...filter,
      questionnaireAnswered: questionnaireAnsweredTransformed,
      questionnaire: questionnaireTransformed,
      nextActionDate: nextActionDateTransformed,
      dateRange: dateRangeTransformed,
      sources: sourcesTransformed,
      searchQuery: searchQuery || undefined,
    };

    const data = await this.get<TGetLeadsResponse>(
      `/accounts/${accountId}/leads`,
      {
        params: {
          ...adaptedFilter,
          sortBy: sortByTransformed,
          includeSpeedScores: includeSpeedScores || undefined,
          limit: pageSize,
          offset: pageSize * page,
        },
      }
    );

    return keysToCamel(data);
  }

  async getLeadsAvailableFilters(
    accountId: number,
    subset?: LeadSimpleFilterTypes | LeadSimpleFilterTypes[],
    properties?: number[]
  ): Promise<Partial<TAvailableLeadsFilters>> {
    const { data } = await this.get(`/accounts/${accountId}/leads/filters`, {
      params: {
        subset,
        properties,
      },
    });

    // modify questionnaire answered if present
    if (!!data[LeadSimpleFilterTypes.QUESTIONNAIRE_ANSWERED]) {
      data[
        LeadSimpleFilterTypes.QUESTIONNAIRE_ANSWERED
      ] = questionnaireAnsweredBoolToEnum(
        data[LeadSimpleFilterTypes.QUESTIONNAIRE_ANSWERED]
      );
    }

    return data;
  }

  async getLeadWithDetails(accountId: number, leadId: number) {
    const lead = await this.get<TLeadWithDetails>(
      `/accounts/${accountId}/leads/${leadId}`
    );

    return keysToCamel(lead);
  }

  async getLeadReapitData(
    accountId: number,
    leadId: number
  ): Promise<Pick<TLeadWithDetails, "reapitData">> {
    const reapitData = await this.get<TLeadReapitData>(
      `/accounts/${accountId}/leads/${leadId}/reapit`
    );

    return {
      reapitData,
    };
  }

  async getLeadMRIRadarData(
    accountId: number,
    leadId: number
  ): Promise<Pick<TLeadWithDetails, "mriRadarData">> {
    const mriRadarData = await this.get<TLeadMRIRadarData>(
      `/accounts/${accountId}/leads/${leadId}/mri-radar`
    );

    return {
      mriRadarData,
    };
  }

  async getLeadOpenViewData(
    accountId: number,
    leadId: number
  ): Promise<Pick<TLeadWithDetails, "openViewData">> {
    const openViewData = await this.get<TLeadOpenViewData>(
      `/accounts/${accountId}/leads/${leadId}/openview`
    );

    return {
      openViewData,
    };
  }

  async getLeadAltoSyncData(
    accountId: number,
    leadId: number
  ): Promise<Pick<TLeadWithDetails, "altoSyncData">> {
    const altoSyncData = await this.get<TLeadAltoSyncData>(
      `/accounts/${accountId}/leads/${leadId}/alto`
    );

    return {
      altoSyncData,
    };
  }

  async getLeadUrlSyncData(
    accountId: number,
    leadId: number
  ): Promise<Pick<TLeadWithDetails, "urlSyncData">> {
    const urlSyncData = await this.get<TLeadUrlSyncData>(
      `/accounts/${accountId}/leads/${leadId}/url-sync`
    );

    return {
      urlSyncData,
    };
  }

  async updateLead(
    accountId: number,
    leadId: number,
    data: Partial<TLeadWithDetails>
  ) {
    const lead = await this.patch<TLeadWithDetails>(
      `/accounts/${accountId}/leads/${leadId}`,
      data
    );

    return keysToCamel(lead);
  }

  async updateLeads(
    accountId: number,
    leadIds: number[],
    data: Partial<TLeadWithDetails>
  ) {
    const leads = await this.patch<TLeadWithDetails[]>(
      `/accounts/${accountId}/leads/update-many`,
      {
        leadIds,
        data,
      }
    );

    return keysToCamel(leads);
  }

  async addLeadContactNote(
    accountId: number,
    personId: number,
    content: string
  ): Promise<TContactNote> {
    return this.post(`/accounts/${accountId}/persons/${personId}/notes`, {
      content,
    });
  }

  async archiveLeads(accountId: number, beforeDate: string): Promise<void> {
    return this.put(`/accounts/${accountId}/leads/archive`, {
      beforeDate,
    });
  }

  async fetchAssignableUsersForSingleLead(
    accountId: number,
    leadId: number
  ): Promise<TAssignableToLeadUserData[]> {
    return this.get(`/accounts/${accountId}/leads/${leadId}/assignable-to`);
  }

  async fetchAssignableUsersForMultipleLeads(
    accountId: number,
    leadIds: number[]
  ): Promise<TAssignableToLeadUserData[]> {
    return this.get(`/accounts/${accountId}/leads/assignable-to`, {
      params: {
        leadIds,
      },
    });
  }

  async syncLeadWithReapit(
    accountId: number,
    leadId: number,
    data: TLeadReapitSyncData
  ): Promise<Pick<TLeadWithDetails, "reapitData">> {
    const reapitData = await this.post<TLeadReapitData>(
      `/accounts/${accountId}/leads/${leadId}/reapit`,
      data
    );

    return {
      reapitData,
    };
  }

  async syncLeadWithMRIRadar(
    accountId: number,
    leadId: number
  ): Promise<Pick<TLeadWithDetails, "mriRadarData">> {
    const mriRadarData = await this.post<TLeadMRIRadarData>(
      `/accounts/${accountId}/leads/${leadId}/mri-radar`
    );

    return {
      mriRadarData,
    };
  }

  async syncLeadWithUrl(
    accountId: number,
    leadId: number
  ): Promise<Pick<TLeadWithDetails, "urlSyncData">> {
    const urlSyncData = await this.post<TLeadUrlSyncData>(
      `/accounts/${accountId}/leads/${leadId}/url-sync`
    );
    return {
      urlSyncData,
    };
  }

  async syncLeadWithOpenView(
    accountId: number,
    leadId: number,
    syncData: TLeadOpenViewSyncData
  ): Promise<Pick<TLeadWithDetails, "openViewData">> {
    const openViewData = await this.post<TLeadOpenViewData>(
      `/accounts/${accountId}/leads/${leadId}/openview`,
      syncData
    );

    return {
      openViewData,
    };
  }

  async syncLeadWithAlto(
    accountId: number,
    leadId: number,
    address: TLeadAltoAddress
  ): Promise<Pick<TLeadWithDetails, "altoSyncData">> {
    const altoSyncData = await this.post<TLeadAltoSyncData>(
      `/accounts/${accountId}/leads/${leadId}/alto`,
      {
        address,
      }
    );

    return {
      altoSyncData,
    };
  }

  async manuallyAddLead(
    accountId: number,
    data: TManualAddLeadData
  ): Promise<void> {
    await this.post<TLead>(`/accounts/${accountId}/leads`, data);
  }
}
