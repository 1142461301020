const BASE_URL =
  "https://images-lead-pro.s3.eu-west-2.amazonaws.com/integration-logo";

export enum IntegrationLogoIcons {
  MRI = "MRI",
  REAPIT = "REAPIT",
  OPEN_VIEW = "OPEN_VIEW",
  URL_SYNC = "URL_SYNC",
  ALTO = "ALTO",
}

export const IntegrationLogoIconURLs: {
  [key in IntegrationLogoIcons]: string;
} = {
  [IntegrationLogoIcons.MRI]: `${BASE_URL}/mri-icon.png`,
  [IntegrationLogoIcons.REAPIT]: `${BASE_URL}/reapit-icon.png`,
  [IntegrationLogoIcons.OPEN_VIEW]: `${BASE_URL}/iamproperty-crm.png`,
  [IntegrationLogoIcons.URL_SYNC]: `${BASE_URL}/url-sync.png`,
  [IntegrationLogoIcons.ALTO]: `${BASE_URL}/alto.jpeg`,
};
