import { Box } from "@chakra-ui/react";
import React from "react";

interface IRadioInputV2Props {
  name: string;
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  isChecked: boolean;
  isDisabled?: boolean;
}

export const RadioInputV2: React.FC<IRadioInputV2Props> = ({
  name,
  value,
  onChange,
  isChecked,
  isDisabled,
}) => {
  return (
    <Box
      as="label"
      display="inline-flex"
      alignItems="center"
      cursor={isDisabled ? "not-allowed" : "pointer"}
    >
      <Box
        as="input"
        type="radio"
        name={name}
        value={value}
        onChange={onChange}
        checked={isChecked}
        disabled={isDisabled}
        display="none"
      />
      <Box
        width="16px"
        height="16px"
        borderRadius="full"
        border="2px solid"
        borderColor={isChecked ? "leadpro.800" : "gray.400"}
        bg={isChecked ? "leadpro.800" : "white"}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        {isChecked && (
          <Box width="6px" height="6px" borderRadius="full" bg="white" />
        )}
      </Box>
    </Box>
  );
};
